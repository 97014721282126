// COMPONENTS
import React from 'react'
// CUSTOM TEMPLATES
import LandingPage from 'templates/LandingPage'
// CONTENT
import content from 'content/lp/hero-test.yml'

// Hero landing page test
const heroTestLP = () => {
  return <LandingPage content={content} />
}
export default heroTestLP
